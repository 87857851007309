//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    name: {
      type: String,
      default: "name",
    },
    title: {
      type: String,
      default: "title goes here",
    },
    image: {
      type: String,
      default: "/default_avatar_m.png",
    },
  },
  mounted() {},
};
