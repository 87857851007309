//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BarPlus from "@/components/animated/BarPlus";
import PlayX3Bar from "@/components/animated/PlayX3Bar";
import SubmitNow from "@/components/SubmitNow";
export default {
  components: {
    BarPlus,
    PlayX3Bar,
    SubmitNow,
  },

  data() {
    return {};
  },
  computed: {},
  mounted() {
    setTimeout(this.add_animation, 100);
  },
  methods: {
    add_animation() {
      // this.$gsap.to(this.monthly_cash, {
      //   duration: 0.8,
      //   val: this.monthly_cash.total,
      // });
    },
  },
};
