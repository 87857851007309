//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    rtl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(this.add_animation, 100);
  },
  methods: {
    add_animation() {
      this.$gsap.fromTo(
        this.$refs.bar,
        {
          x: this.rtl ? 300 : -300,
        },
        {
          x: 0,
          duration: 0.5,
          scrollTrigger: this.$refs.bar,
        }
      );
      this.$gsap.fromTo(
        this.$refs.plus,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          scrollTrigger: this.$refs.plus,
        }
      );
    },
  },
};
