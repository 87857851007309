//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    icon: {
      type: String,
      default: "mdi-circle",
    },
    title: {
      type: String,
      default: "Dummy Title",
    },
    desc: {
      type: String,
      default: "Send a summary to me",
    },
  },
  mounted() {
    setTimeout(this.add_animation, 100)
  },
  methods: {
    add_animation(){
      this.$gsap.from(this.$el, {
      scale: 0,
      opacity: 0,
      duration: 0.6,
      ease: "back",
      scrollTrigger: {
        trigger: this.$el,
        start: "top 80%",
      },
    });
    }
  }
};
