//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BarHammer from "@/components/animated/BarHammer";
export default {
  components: {
    BarHammer,
  },
  data() {
    return {
      items: [
        {
          title: "Watch & Spot",
          desc:
            "Curation is an art. Spot the best content and win rewards while you're at it",
          icon: "mdi-play-circle",
        },
        {
          title: "Rate & Review",
          desc: "Be the jury and help us pick the best films out there",
          icon: "mdi-thumb-up",
        },
        {
          title: "Recommend",
          desc: "our users recommend your films to their network",
          icon: "mdi-bullhorn",
        },
        {
          title: "Match with the Celebs' Consensus",
          desc:
            "Every month find how your recommendations match with our partner celebrities. The higher the match, the higher the rewards",
          icon: "mdi-star",
        },
        {
          title: "Engagement Boost",
          desc:
            "Shoot up your engagement scores and become a standout curator on our platform",
          icon: "mdi-trending-up",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    setTimeout(this.add_animation, 100);
  },
  methods: {
    add_animation() {
      this.$refs.lefts.forEach((section) => {
        this.$gsap.from(section, {
          opacity: 0,
          x: -100,
          duration: 0.6,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
          },
        });
      });
      this.$refs.rights.forEach((section) => {
        this.$gsap.from(section, {
          opacity: 0,
          x: 100,
          duration: 0.8,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
          },
        });
      });
    },
  },
};
