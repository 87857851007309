//
//
//
//
//
//
//

export default {
  data() {
    return {};
  },
  mounted() {
    this.$gsap.fromTo(
      this.$el,
      { x: -100 },
      { x: 0, duration: 0.5, scrollTrigger: this.$el }
    );
  },
};
