//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BarHammer from "@/components/animated/BarHammer";

export default {
  components: {
    BarHammer,
  },
  data() {
    return {
      items: [
        {
          title: "Film Submission",
          desc: "You submit your film on our platform",
          icon: "mdi-plus",
        },
        {
          title: "Film Screening",
          desc: "We screen your film after verification",
          icon: "mdi-play",
        },
        {
          title: "Curator Recommends",
          desc: "Our users recommend your films to their network",
          icon: "mdi-bullhorn",
        },
        {
          title: "Celebrity Recommends",
          desc:
            "Your film stands a chance to get recommended by our Partner Celebrities",
          icon: "mdi-star",
        },
        {
          title: "Popularity Boost",
          desc:
            "Be a standout creator and shoot up your popularity score by winning titles and receiving recommendations",
          icon: "mdi-trending-up",
        },
      ],
    };
  },
  mounted() {
    setTimeout(this.add_animation, 100);
  },
  computed: {},
  methods: {
    add_animation() {
      this.$refs.lefts.forEach((section) => {
        this.$gsap.from(section, {
          opacity: 0,
          x: -100,
          duration: 0.6,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
          },
        });
      });
      this.$refs.rights.forEach((section) => {
        this.$gsap.from(section, {
          opacity: 0,
          x: 100,
          duration: 0.8,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
          },
        });
      });
    },
  },
};
