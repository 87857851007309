//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Bar from "@/components/animated/Bar";
import CircularItem from "@/components/welcome/CircularItem";
export default {
  components: {
    Bar,
    CircularItem,
  },
  data() {
    return {
      items: [
        {
          icon: "mdi-trophy",
          title: "₹ 15,000",
          desc: "Creator of the Month Title",
        },
        {
          icon: "mdi-youtube",
          title: "Drive Views",
          desc: "to your YouTube channel",
        },
        {
          icon: "mdi-star",
          title: "Celebrities",
          desc: "recommend your films",
        },
        {
          icon: "mdi-domino-mask",
          title: "Recognition",
          desc: "focussed profiling",
        },
        {
          icon: "mdi-cash-multiple",
          title: "Get Funded",
          desc: "for your upcoming projects",
        },
        {
          icon: "mdi-video-account",
          title: "Social Media",
          desc: "campaigns for your films",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
};
