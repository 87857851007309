//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Bar from "@/components/animated/Bar";
import CircularItem from "@/components/welcome/CircularItem";
export default {
  components: {
    Bar,
    CircularItem,
  },
  data() {
    return {
      items: [
        {
          title: "Win Exclusive Giveaways",
          desc: "Curator of the Month Title",
          icon: "mdi-trophy",
        },
        {
          title: "Be the Jury",
          desc: "Rate & Review Films",
          icon: "mdi-checkbox-multiple-marked-outline",
        },
        {
          title: "Recommend",
          desc: "good content and win rewards",
          icon: "mdi-bullhorn",
        },
        {
          title: "Recognition",
          desc: "focussed profiling",
          icon: "mdi-domino-mask",
        },
        {
          title: "Curate",
          desc: "list and maintain your film journal",
          icon: "mdi-playlist-star",
        },
        {
          title: "Build Network",
          desc: "and share the love for cinema",
          icon: "mdi-account-group",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
};
