//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from "@/layouts/Base";
import Slide1 from "@/components/welcome/Slide1";
import Slide2 from "@/components/welcome/Slide2";
import Slide3 from "@/components/welcome/Slide3";
import Slide4 from "@/components/welcome/Slide4";
import Slide5 from "@/components/welcome/Slide5";
import Slide6 from "@/components/welcome/Slide6";
import Slide7 from "@/components/welcome/Slide7";
import Slide8 from "@/components/welcome/Slide8";
import Slide9 from "@/components/welcome/Slide9";
import Slide10 from "@/components/welcome/Slide10";
export default {
  metaInfo: {
    title: "Welcome",
  },
  components: {
    Slide1,
    Slide2,
    Slide3,
    Slide4,
    Slide5,
    Slide6,
    Slide7,
    Slide8,
    Slide9,
    Slide10,
    BaseLayout,
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(this.add_animation, 10);
  },
  methods: {
    add_animation() {
      this.$gsap.from(this.$refs.fab.$el, {
        xPercent: 120,
        scale: 0,
        opacity: 0,
        scrollTrigger: {
          trigger: this.$refs.start,
          start: "top 30px",
          scrub: true,
        },
      });
    },
  },
};
