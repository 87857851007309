//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    setTimeout(this.add_animation, 100);
  },
  methods: {
    add_animation() {
      this.$refs.play.forEach((play, index) => {
        this.$gsap.from(play.$el, {
          x: -100,
          duration: 1.8 - 0.4 * (index + 1),
          scrollTrigger: {
            trigger: play.$el,
          },
        });
      });

      this.$gsap.from(this.$refs.bar, {
        x: 200,
        duration: 1,
        scrollTrigger: {
          trigger: this.$refs.bar,
        },
      });
    },
  },
};
