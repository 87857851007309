//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {
    this.$gsap.fromTo(
      this.$refs.bar,
      {
        x: -300,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        scrollTrigger: this.$refs.bar,
      }
    );
    this.$gsap.fromTo(
      this.$refs.hammer,
      {
        x: 200,
      },
      {
        x: 0,
        duration: 2,
        scrollTrigger: this.$refs.hammer,
      }
    );
  },
};
