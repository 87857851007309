//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BarHammer from "@/components/animated/BarHammer";
import PlayX3Bar from "@/components/animated/PlayX3Bar";
export default {
  components: { BarHammer, PlayX3Bar },
  data() {
    return {
      contacts: [
        {
          text: "+91 8400334876",
          href: "tel:+918400334876",
          icon: "mdi-phone",
          action: "phone",
        },
        {
          text: "contactus@moviepediafilms.com",
          href: "mailto:contactus@moviepediafilms.com",
          icon: "mdi-email",
          action: "email",
        },
        {
          text: "+91 9711487501",
          href: "https://wa.me/919711487501",
          icon: "mdi-whatsapp",
          action: "whatsapp",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    setTimeout(this.add_animation, 100);
  },
  methods: {
    add_animation() {
      this.$refs.icons.forEach((icon, index) => {
        this.$gsap.from(icon, {
          scale: 0,
          duration: 0.3 + 0.3 * index,
          ease: "back",
          scrollTrigger: icon,
        });
      });
      this.$refs.content.forEach((text) => {
        this.$gsap.from(text, {
          opacity: 0,
          duration: 0.5,
          scrollTrigger: text,
        });
      });
    },
    on_action(item) {
      this.$refs[item.action][0].click();
    },
  },
};
